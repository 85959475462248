import { createSlice } from '@reduxjs/toolkit';

export interface LayoutProps {
  isSideNavigationVisible: boolean;
  isSidemenuIconView: boolean;
  showResourceFilter: boolean;
  selectedResourceFilter: string;
}

const initialState: LayoutProps = {
  isSideNavigationVisible: false,
  isSidemenuIconView: false,
  showResourceFilter: false,
  selectedResourceFilter: 'kinder',
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    toggleSideMenu: {
      reducer: (state, action: any) => {
        state.isSideNavigationVisible = action.payload.showSideMenu;
      },
      prepare: (showSideMenu: boolean): any => {
        return {
          payload: {
            showSideMenu,
          },
        };
      },
    },
    toggleSidemenuIconView: {
      reducer: (state, action: any) => {
        state.isSidemenuIconView = action.payload.isIconView;
      },
      prepare: (isIconView: boolean): any => {
        return {
          payload: {
            isIconView,
          },
        };
      },
    },
    toggleResourceFilterVisible: {
      reducer: (state, action: any) => {
        state.showResourceFilter = action.payload.show;
      },
      prepare: (show: boolean): any => {
        return {
          payload: {
            show,
          },
        };
      },
    },
    setSelectedResourceFilter: {
      reducer: (state, action: any) => {
        state.selectedResourceFilter = action.payload.filterId;
      },
      prepare: (filterId: string): any => {
        return {
          payload: {
            filterId,
          },
        };
      },
    },
  },
});

export default layoutSlice.reducer;

export const { toggleSideMenu, toggleSidemenuIconView, toggleResourceFilterVisible, setSelectedResourceFilter } =
  layoutSlice.actions;
