export const menuItems = [
  {
    displayName: 'Resources',
    urlPath: '/tr/live',
    isProtected: true,
    hideAfterLogin: false,
    defaultSelected: true,
  },
  {
    displayName: 'Users',
    urlPath: '/users',
    isProtected: true,
    hideAfterLogin: false,
  },
  {
    displayName: 'Mailer',
    urlPath: '/mailer',
    isProtected: true,
    hideAfterLogin: false,
  },
];

export const sideMenuItems = [
  {
    displayName: 'TR Dashboard',
    urlPath: 'tr/live',
    theme: 'green',
    mainPath: 'tr',
    isCollapsed: true,
    submenu: [
      {
        displayName: 'TR Live View',
        urlPath: 'tr/live',
      },
      {
        displayName: 'Concept Zone',
        urlPath: 'tr/conceptzones',
        chainUrls: ['tr/conceptzones/form'],
      },
      {
        displayName: 'Zone Pages',
        urlPath: 'tr/zonepages',
        chainUrls: ['tr/zonepages/form'],
      },
      {
        displayName: 'Element Group',
        urlPath: 'tr/elementgroups',
        chainUrls: ['tr/elementgroups/form', 'tr/elementgroups/form/preview'],
      },
      {
        displayName: 'Elements',
        urlPath: 'tr/elements',
        chainUrls: ['tr/elements/form'],
      },
      {
        displayName: 'Concepts',
        urlPath: 'tr/concepts',
        chainUrls: ['tr/concepts/form'],
      },
      {
        displayName: 'Sequences',
        urlPath: 'tr/sequences',
        chainUrls: ['tr/sequences/form'],
      },
      {
        displayName: 'Description Texts',
        urlPath: 'tr/descriptions',
      },
      {
        displayName: 'Page Builder',
        urlPath: 'tr/pagebuilder',
        chainUrls: ['tr/pagebuilder/create-page', 'tr/pagebuilder/edit-page'],
      },
      {
        displayName: 'PDF Uploader',
        urlPath: 'tr/pdf-uploader',
      },
      {
        displayName: 'File Uploader',
        urlPath: 'tr/file-uploader',
      },
    ],
  },
  {
    displayName: 'SLR Dashboard',
    urlPath: 'slr/live',
    theme: 'blue',
  },
  {
    displayName: '4TD Dashboard',
    urlPath: '4td/live',
    theme: 'purple',
  },
  {
    displayName: 'PLR Dashboard',
    urlPath: 'plr/live',
    theme: 'red',
  },
  {
    displayName: 'DVR Dashboard',
    urlPath: 'dvr/live',
    theme: 'yellow',
    mainPath: 'dvr',
    isCollapsed: true,
    submenu: [
      {
        displayName: 'Concept Zone',
        urlPath: 'dvr/conceptzones',
        chainUrls: ['dvr/conceptzones/form'],
      },
      {
        displayName: 'Element Group',
        urlPath: 'dvr/elementgroups',
        chainUrls: ['dvr/elementgroups/form', 'dvr/elementgroups/form/preview'],
      },
      {
        displayName: 'Free Resources',
        urlPath: 'dvr/free-resources',
      },
      {
        displayName: `What's New Resources`,
        urlPath: 'dvr/whats-new-resources',
      },
      {
        displayName: 'Elements',
        urlPath: 'dvr/elements',
        chainUrls: ['dvr/elements/form'],
      },
      {
        displayName: 'Concepts',
        urlPath: 'dvr/concepts',
        chainUrls: ['dvr/concepts/form'],
      },
      {
        displayName: 'Description Text',
        urlPath: 'dvr/descriptions'
      },
    ],
  },
];

export const resourceByGrade = [
  {
    id: 'kinder',
    name: 'Foundational / Prep / Reception / Kindergarten',
    color: 'green',
    bg_faded_color: 'bg-green-l3',
  },
  {
    id: 'y1',
    name: 'Year 1',
    color: 'yellow',
    bg_faded_color: 'bg-yellow-l3',
  },
  {
    id: 'y2',
    name: 'Year 2',
    color: 'red',
    bg_faded_color: 'bg-red-l3',
  },
  {
    id: 'y3',
    name: 'Year 3',
    color: 'purple',
    bg_faded_color: 'bg-purple-l3',
  },
  {
    id: 'y4',
    name: 'Year 4',
    color: 'blue',
    bg_faded_color: 'bg-blue-l3',
  },
  {
    id: 'y5',
    name: 'Year 5',
    color: 'green',
    bg_faded_color: 'bg-green-l3',
  },
  {
    id: 'y6',
    name: 'Year 6',
    color: 'yellow',
    bg_faded_color: 'bg-yellow-l3',
  },
];

export const resourceByConcept = [
  {
    id: 'c1',
    name: 'Number + Place Value + Addition And Subtraction + Money + Algebra',
    color: 'green',
    bg_faded_color: 'bg-green-l3',
  },
  {
    id: 'c2',
    name: 'Multiplication & Division + Fractions + Place Value + Algebra',
    color: 'yellow',
    bg_faded_color: 'bg-yellow-l3',
  },
  {
    id: 'c3',
    name: 'Fractions + Multiplication & Division + Time + Place Value + Measurement + Geometry',
    color: 'red',
    bg_faded_color: 'bg-red-l3',
  },
  {
    id: 'c4',
    name: 'Measurement + Geometry + Place Value + Fractions',
    color: 'purple',
    bg_faded_color: 'bg-purple-l3',
  },
  {
    id: 'c5',
    name: 'Patterns + Multiplication & Division',
    color: 'blue',
    bg_faded_color: 'bg-blue-l3',
  },
  {
    id: 'c6',
    name: 'Time + Position + Fractions',
    color: 'green',
    bg_faded_color: 'bg-green-l3',
  },
  {
    id: 'c7',
    name: 'Position + Time + Place Value',
    color: 'yellow',
    bg_faded_color: 'bg-yellow-l3',
  },
  {
    id: 'c8',
    name: 'Statistics & Probability + Fractions + Place Value',
    color: 'red',
    bg_faded_color: 'bg-red-l3',
  },
];

export const boxColor = new Map();
boxColor.set(0, 'green');
boxColor.set(1, 'yellow');
boxColor.set(2, 'red');
boxColor.set(3, 'purple');
boxColor.set(4, 'blue');