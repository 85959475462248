import { baseApi } from '../baseApi';
import { gql } from 'graphql-request';

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder: any) => ({
    // queries

    // mutations
    login: builder.mutation({
      query: ({ userEmailorUsername, password }: any) => ({
        body: gql`
          mutation Login($userEmailorUsername: String, $password: String, $subsite: String) {
            login(userEmailorUsername: $userEmailorUsername, password: $password, subsite: $subsite) {
              id
              access_token
              refresh_token
              expires_at
              browser
              platform
            }
          }
        `,
        variables: {
          userEmailorUsername,
          password,
          subsite: 'MAIN',
        },
      }),
      transformResponse: (res: any): any => res.login,
    }),
    logout: builder.mutation({
      query: () => ({
        body: gql`
          mutation Logout {
            logout {
              success
            }
          }
        `,
      }),
      transformResponse: (res: any): any => res.logout,
    }),
    requestResetPassword: builder.mutation({
      query: (emailOrMobile: String) => ({
        body: gql`
          mutation RequestResetPassword($emailOrMobile: String) {
            requestResetPassword(emailOrMobile: $emailOrMobile) {
              id
            }
          }
        `,
        variables: {
          emailOrMobile,
        },
      }),
      transformResponse: (res: any): any => res.requestResetPassword,
    }),
    changeRandomPassword: builder.mutation({
      query: ({emailOrMobile, password} : any) => ({
        body: gql`
          mutation changeRandomPassword($emailOrMobile: String, $password: String) {
            changeRandomPassword(emailOrMobile: $emailOrMobile, password: $password) {
              random_password
            }
          }
        `,
        variables: {
          emailOrMobile,
          password,
        },
      }),
      transformResponse: (res: any): any => res.changeRandomPassword,
    }),
  }),
  overrideExisting: false,
});
export const { useLoginMutation, useLogoutMutation, useRequestResetPasswordMutation, useChangeRandomPasswordMutation } = authApi;
