import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/storeHooks';
import { toggleSideMenu, toggleSidemenuIconView, toggleResourceFilterVisible } from '../store/layoutSlice';
import { isCurrentMenuUseResourceFilter } from '../Utils/utilityFunctions';
import cookieHelper from '../../../helpers/cookieHelper';

function useLayout() {
  const location = useLocation();
  const dispatcher = useAppDispatch();
  const { isSideNavigationVisible, isSidemenuIconView, showResourceFilter } = useAppSelector((state) => state.layout);

  useEffect(() => {
    //this use Effect monitors when to show side menu
    //only show side menu when the url path is a sidemenu path
    dispatcher(
      toggleSideMenu(
        cookieHelper.isLoggedIn() &&
          location.pathname !== '/notauthorized' &&
          location.pathname !== '/pdf' &&
          location.pathname !== '/'
      )
    );

    //always check if we need to hide resource filter
    dispatcher(toggleResourceFilterVisible(isCurrentMenuUseResourceFilter(location.pathname)));
  }, [location.pathname]);

  const toggleIconView = () => {
    dispatcher(toggleSidemenuIconView(!isSidemenuIconView));
  };

  const setShowSideMenu = (show: boolean) => {
    dispatcher(toggleSideMenu(show));
  };

  return { isSideNavigationVisible, isSidemenuIconView, showResourceFilter, toggleIconView, setShowSideMenu };
}

export default useLayout;
