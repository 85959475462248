import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../../../../store/store';

interface FileUploaderPageState {
  fileData: File | undefined;
  fileId: string;
  isDeleteModalOpen: boolean;
  isExistsModalOpen: boolean;
  isUpdateModalOpen: boolean;
  isOverwriteFile: boolean;
  existsUpload: {
    fileId: string;
    file: File | undefined;
  };
}

const initialState: FileUploaderPageState = {
  fileData: undefined,
  fileId: '',
  isDeleteModalOpen: false,
  isExistsModalOpen: false,
  isUpdateModalOpen: false,
  isOverwriteFile: false,
  existsUpload: {
    fileId: '',
    file: undefined,
  },
};

export const fileUploaderPageSlice = createSlice({
  name: 'fileUploaderPage',
  initialState,
  reducers: {
    reset: (state) => {
      state = initialState;
    },
    setFile: (state, action: PayloadAction<FileUploaderPageState['fileData']>) => {
      state.fileData = action.payload;
    },
    setFileId: (state, action: PayloadAction<FileUploaderPageState['fileId']>) => {
      state.fileId = action.payload;
    },
    openDeleteModal: (state) => {
      state.isDeleteModalOpen = true;
    },
    closeDeleteModal: (state) => {
      state.isDeleteModalOpen = false;
    },
    toggleDeleteModal: (state) => {
      state.isDeleteModalOpen = !state.isDeleteModalOpen;
    },
    openExistsModal: (state) => {
      state.isExistsModalOpen = true;
    },
    closeExistsModal: (state) => {
      state.isExistsModalOpen = false;
    },
    toggleExistsModal: (state) => {
      state.isExistsModalOpen = !state.isExistsModalOpen;
    },
    openUpdateModal: (state) => {
      state.isUpdateModalOpen = true;
    },
    closeUpdateModal: (state) => {
      state.isUpdateModalOpen = false;
    },
    toggleUpdateModal: (state) => {
      state.isUpdateModalOpen = !state.isExistsModalOpen;
    },
    setIsOverwriteFile: (state, action: PayloadAction<FileUploaderPageState['isOverwriteFile']>) => {
      state.isOverwriteFile = action.payload;
    },
    setExistsUpload: (state, action: PayloadAction<FileUploaderPageState['existsUpload']>) => {
      state.existsUpload = action.payload;
    },
    resetExistsUpload: (state) => {
      state.existsUpload = initialState.existsUpload;
    },
  },
});

export const {
  reset,
  setFile,
  setFileId,
  openDeleteModal,
  closeDeleteModal,
  toggleDeleteModal,
  openExistsModal,
  closeExistsModal,
  toggleExistsModal,
  openUpdateModal,
  closeUpdateModal,
  toggleUpdateModal,
  setIsOverwriteFile,
  setExistsUpload,
  resetExistsUpload,
} = fileUploaderPageSlice.actions;

export const selectFileUploader = (state: RootState) => state.fileUploaderPage;

export default fileUploaderPageSlice.reducer;
