import React, { Suspense, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import cookie from '../../helpers/cookieHelper';
import LoadingScreen from '../../components/Loading/LoadingScreen/LoadingScreen';
import config from '../../config';

function HasAuthenticatedWrapper() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (cookie.isLoggedIn()) {
      navigate(config.defaultProtectedRoute);
    }
  }, []);

  //ensures that the screen scrolls to top when location changes
  useEffect(() => window.scrollTo(0, 0), [location]);

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Outlet />
    </Suspense>
  );
}

export default HasAuthenticatedWrapper;
