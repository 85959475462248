import React, { Suspense, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Outlet, useNavigate } from 'react-router-dom';
import cookie from '../../helpers/cookieHelper';
import LoadingScreen from '../../components/Loading/LoadingScreen/LoadingScreen';
import { useGetUserContextQuery } from '../../store/services/userContextApi';
import config from '../../config';
import useLayout from '../../features/Layout/hooks/useLayout';
import { redirectLogin } from '../../helpers/utilityFunctions';

function ProtectedRouteWrapper() {
  const { data: userData, isLoading, isFetching } = useGetUserContextQuery('');
  const { setShowSideMenu } = useLayout();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  //ensures that the screen scrolls to top when location changes
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, [location]);

  useEffect(() => {
    if (!cookie.isLoggedIn()) {
      redirectLogin();
    } else {
      setIsAuthenticated(true);
    }
  }, []);

  const isAdmin = (roles: any[]): boolean => {
    return roles ? roles.some((x: any) => x.role.short_code === 'MA') : false;
  };

  useEffect(() => {
    const user = userData as any;

    if (user) {
      //if the user is logged in but not yet verified and trying to access protected
      //routes other than emailverification, redirect to emailverification page
      if (!user?.is_verified && !location.pathname.includes('emailverification')) {
        window.location.href = `${config.subsitesUrl.main}/createaccount/${
          user?.is_institution ? 'school' : 'personal'
        }/emailverification/${user?.email}/true`;
      }

      //check if user is an admin
      if (!isAdmin(user.roles)) {
        navigate('/notauthorized');
        setShowSideMenu(false);
      }
    }
  }, [userData, isLoading, isFetching]);

  if (!isAuthenticated || !userData) <LoadingScreen />;

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Outlet />
    </Suspense>
  );
}

export default ProtectedRouteWrapper;
