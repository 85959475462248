import React from 'react';
import './Layout.scss';
import Navigation from './Navigations/Navigation';
import SideNavigation from './SideNavigation/SideNavigation';
import Background from './Background/Background';
// import ResourceFilter from './ResourceFilter/ResourceFilter';
import useLayout from './hooks/useLayout';

interface LayoutProps {
  children: JSX.Element;
}

function Layout({ children }: LayoutProps) {
  const { isSideNavigationVisible, isSidemenuIconView, showResourceFilter } = useLayout();

  return (
    <>
      <Navigation />
      <main className="flex flex-row">
        <SideNavigation />
        {/* <ResourceFilter /> */}

        <section
          className={`right-content flex flex-column${isSideNavigationVisible ? ' sidemenu-visible' : ''}${
            isSidemenuIconView ? ' sidemenu-icon-view' : ''
          }${showResourceFilter ? ' resource-filter-visible' : ''}`}
        >
          <div className="page-content flex flex-column">
            {children}
            <Background />
          </div>
        </section>
      </main>
    </>
  );
}

export default Layout;
