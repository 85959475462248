import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


interface ImageProps {
  src: string;
  placeholder?: string;
  alt?: string;
  className?: string;
  blurry?: boolean;
}

function Image({ src, placeholder, alt, className, blurry }: ImageProps) {
  return (
    <LazyLoadImage src={src}
        placeholderSrc={placeholder}
        alt={alt}
        className={className}
        effect={blurry ? "blur"  : undefined}
    />
  );
}

export default Image;
