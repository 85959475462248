import React, { useEffect, useState } from 'react';
import './Navigation.scss';
import config from '../../../config';
import { Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { AlpLogo } from '../../../helpers/imagePreloader';
import { userContextApi, useLazyGetUserContextQuery } from '../../../store/services/userContextApi';
import cookie from '../../../helpers/cookieHelper';
import UserCard from '../UserCard/UserCard';
import { menuItems } from '../Utils/constants';
import Image from '../../../components/Image/Image';

function Navigation() {
  // @ts-ignore
  const { data: userData } = userContextApi.endpoints.getUserContext.useQueryState('', {
    selectFromResult: (result: any) => result,
  });

  const [getUserContext] = useLazyGetUserContextQuery();
  const [name, setName] = useState('');

  useEffect(() => {
    if (cookie.isLoggedIn()) getUserContext('');
  }, []);

  useEffect(() => {
    const user = userData as any;

    if (user) {
      let name = '';
      if (user.is_institution) {
        name = user.institution_profile ? user.institution_profile?.institution_name : '';
      } else {
        name = user.user_profile ? `${user.user_profile?.first_name} ${user.user_profile?.last_name}` : '';
      }

      name.trim() !== '' ? setName(name) : setName(user.email);
    }
  }, [userData]);

  // const getSubsitesMenu = (): JSX.Element[] => {
  //   return subsites.map((item: any, key: number) => {
  //     const { color } = subsiteThemes.get(item.short_code);
  //     return (
  //       <li key={key}>
  //         <a href={item.url} target="_blank" rel="noreferrer" className={color}>
  //           {item.name}
  //         </a>
  //       </li>
  //     );
  //   });
  // };

  const getMenu = (): JSX.Element[] => {
    return menuItems.map((item: any, key: number) => {
      if ((!item.isProtected && !cookie.isLoggedIn()) || (!item.hideAfterLogin && cookie.isLoggedIn())) {
        return (
          <li key={key}>
            <NavLink to={item.urlPath} className={({ isActive }) => (isActive ? 'menu-selected' : '')} end>
              {item.displayName}

              <div className="selected-underline" />
            </NavLink>

            <div className="selected-underline" />
          </li>
        );
      } else {
        return <React.Fragment key={key} />;
      }
    });
  };

  return (
    <>
      <header className="main-header">
        <div className="navigation-row flex flex-row">
          <div className="flex flex-grow">
            {/* Brand column */}
            <div className="brand-col">
              <a href={`${config.subsitesUrl.main}`}>
                <Image src={AlpLogo} alt="A Learning Place Logo" />
              </a>
            </div>

            <ul className="menu-col flex flex-row">{getMenu()}</ul>
          </div>

          {/* Buttons column */}
          <div className="buttons-col flex flex-row">
            {cookie.isLoggedIn() ? (
              <UserCard name={name} isInstitution={userData?.is_institution} />
            ) : (
              <>
                <a href={`${config.subsitesUrl.main}/login/redirect/${config.subsiteCode}`}>
                  <Button color="link" className="login-btn">
                    Login
                  </Button>
                </a>

                <a href={`${config.subsitesUrl.main}/createaccount`}>
                  <Button className="reversed-color-btn">Create A Free Account</Button>
                </a>
              </>
            )}
          </div>
        </div>
      </header>
      {/* 
      <div className={`subsite-row flex with-shadow${dropdownOpened === 'subsites' ? ' show-subsites' : ''}`}>
        <ul className="flex flex-row">{getSubsitesMenu()}</ul>
      </div> */}
    </>
  );
}

export default Navigation;
