import moment from 'moment';
import config from '../config';

export const getFormattedDate = (dateToFormat?: string, dateformat?: string): string => {
  if (dateToFormat) return `${moment(new Date(dateToFormat ?? '')).format(dateformat ?? 'MMM DD, yyyy')}`;
  else return 'N/A';
};

export const transformDatetoMomentStandard = (date?: Date) => {
  return date
    ? `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
    : '';
};

export const getDifferenceInDays = (dateFrom: Date, dateTo: Date): number => {
  return Math.abs(moment(dateFrom).diff(moment(dateTo), 'days'));
};

export const getDifferenceOfDaysPast = (datefrom: Date, monthsago: number): number => {
  const datepast = moment(datefrom).subtract(monthsago, 'months');
  return Math.abs(Math.abs(moment(datefrom).diff(datepast, 'days')));
};

//appends numbers with appropriate suffix
export const nth = (n: number, dontStyleSuffix?: boolean) =>
  `${n}${!dontStyleSuffix ? `<span class='num-suffix'>` : ''}${
    ['st', 'nd', 'rd'][(((((n < 0 ? -n : n) + 90) % 100) - 10) % 10) - 1] || 'th'
  }${!dontStyleSuffix ? `</span>` : ''}`;

//this makes updating of local storage easier
export const updateLocalStorage = (storageKey: string, newItems: any) => {
  //update localstorage
  const prevpageDetails = localStorage.getItem(storageKey) ? JSON.parse(localStorage.getItem(storageKey) ?? '{}') : undefined;

  if (prevpageDetails) localStorage.setItem(storageKey, JSON.stringify({ ...prevpageDetails, ...newItems }));
};

export const extractErrorMessage = (formStatus: any): string => {
  try {
    if (formStatus?.isError) {
      if (formStatus?.error?.status === 500) return 'Something went wrong. Please contact administrator.';
      else return formStatus?.error?.data?.response?.errors[0]?.message;
    }
  } catch (error) {
    return 'Something went wrong. Please contact administrator.';
  }

  return 'No error found';
};

export const getFormattedPhone = (phone: string): string => {
  const phoneString = phone ? phone.replace(/[^0-9]+/g, '') : phone;
  return phone && phoneString.length >= 10
    ? `${phoneString.substring(0, 4)} ${phoneString.substring(4, 6)} ${phoneString.substring(6, 8)} ${phoneString.substring(
        8,
        10
      )}`
    : phone;
};

export const getAustStateAbbreviations = (state: string) => {
  switch (state) {
    case 'New South Wales':
      return 'NSW';
    case 'Northern Territory':
      return 'NT';
    case 'Queensland':
      return 'Qld';
    case 'South Australia':
      return 'SA';
    case 'Tasmania':
      return 'Tas';
    case 'Victoria':
      return 'Vic';
    case 'Western Australia':
      return 'WA';
    case 'Australian Capital Territory':
      return 'ACT';
    default:
      return state;
  }
};

export const getDecimalDisplay = (numtoDisplay: number) => {
  if (numtoDisplay) {
    const strnum = numtoDisplay.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).split('.');
    return `$ ${strnum[0]}.<span class='decimal'>${strnum[1] ?? '00'}</span>`;
  } else {
    return `$ 0.<span class='decimal'>00</span>`;
  }
};

export const arrayRange = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (value, index) => start + index * step);

//Formats the Size of Files from Javascript
const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
export const formatFileSize = (x: any) => {
  let l = 0,
    n = parseInt(x, 10) || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }

  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
};

export const redirectLogin = () => {
  let redirect = location.pathname;
  if (location.search) redirect += location.search;

  window.location.href = `${config.subsitesUrl.main}/login?redirect=${encodeURIComponent(redirect)}`;
};

export function generateRandomPassword(pMaxLength = 8) {
  const upperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz';
  const numbers = '0123456789';
  const specialCharacters = '!@#$%^&*()_+[]{}|;:,.<>?';

  // Ensuring at least one character from each set
  let password = [
    upperCaseLetters[Math.floor(Math.random() * upperCaseLetters.length)],
    lowerCaseLetters[Math.floor(Math.random() * lowerCaseLetters.length)],
    numbers[Math.floor(Math.random() * numbers.length)],
    specialCharacters[Math.floor(Math.random() * specialCharacters.length)],
  ];

  // Pool of all characters to randomly select from
  const allCharacters = upperCaseLetters + lowerCaseLetters + numbers + specialCharacters;

  // Generate the remaining characters to meet the minimum length (at least 8 characters)
  while (password.length < pMaxLength) {
    password.push(allCharacters[Math.floor(Math.random() * allCharacters.length)]);
  }

  // Shuffle the password array to randomize the order
  password = password.sort(() => Math.random() - 0.5);

  // Join the array into a string and return the password
  return password.join('');
}
