import { combineReducers, configureStore } from '@reduxjs/toolkit';
import type { PreloadedState } from '@reduxjs/toolkit';

import { baseApi } from './baseApi';
import { basePersistentApi } from './baseApi';
import layoutReducer from '../features/Layout/store/layoutSlice';
import fileUploadReducer from '../features/ResourceUploadManager/FileUpload/store/fileUploadSlice';
import previewElementGroupReducer from '../pages/TR/ElementGroup/store/elementGroupSlice';
import checkedConceptZoneReducer from '../pages/TR/ElementGroup/store/conceptZoneSlice';
import tableReducer from '../features/Table/store/tableSlice';
import pageBuilderSliceReducer from '../features/PageBuilder/store/pageBuilderSlice';
import pageBuilderPageReducer from '../pages/TR/PageBuilder/store/pageBuilderPageSlice';
import pdfUploaderPageSliceReducer from '../pages/TR/PDFUploader/store/pdfUploaderPageSlice';
import fileUploaderPageSliceReducer from '../pages/TR/FileUploader/store/fileUploaderPageSlice';

// Create the root reducer separately so we can extract the RootState type
const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  [basePersistentApi.reducerPath]: basePersistentApi.reducer,
  layout: layoutReducer,
  fileUpload: fileUploadReducer,
  previewElement: previewElementGroupReducer,
  checkedConceptZone: checkedConceptZoneReducer,
  table: tableReducer,
  pageBuilder: pageBuilderSliceReducer,
  pageBuilderPage: pageBuilderPageReducer,
  pdfUploaderPage: pdfUploaderPageSliceReducer,
  fileUploaderPage: fileUploaderPageSliceReducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      })
        .concat(baseApi.middleware)
        .concat(basePersistentApi.middleware),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
