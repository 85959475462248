import { createSlice } from '@reduxjs/toolkit';

export interface LayoutProps {
  fileReferenceId: string;
  fileStatus: FileStatusType;
  uploadProgress: number;
}

export type FileStatusType = 'idle' | 'uploading' | 'success' | 'failed';

const initialState: LayoutProps = {
  fileReferenceId: '',
  fileStatus: 'idle',
  uploadProgress: 0,
};

export const fileUploadSlice = createSlice({
  name: 'fileUpload',
  initialState,
  reducers: {
    updateStatus: {
      reducer: (state, action: any) => {
        state.fileReferenceId = action.payload.fileReferenceId;
        state.fileStatus = action.payload.status;
      },
      prepare: (fileReferenceId: string, status: FileStatusType): any => {
        return {
          payload: {
            fileReferenceId,
            status,
          },
        };
      },
    },
    updateStatusNoRef: {
      reducer: (state, action: any) => {
        state.fileStatus = action.payload.status;
      },
      prepare: (status: FileStatusType): any => {
        return {
          payload: {
            status,
          },
        };
      },
    },
    updateUploadProgress: {
      reducer: (state, action: any) => {
        state.uploadProgress = action.payload.progress < 100 ? Math.floor(action.payload.progress) : 0;
        state.fileStatus = action.payload.progress < 100 ? 'uploading' : 'success';
        if (action.payload.progress >= 100) {
          state.fileReferenceId = '';
        }
      },
      prepare: (progress: number): any => {
        return {
          payload: {
            progress,
          },
        };
      },
    },
  },
});

export default fileUploadSlice.reducer;

export const { updateStatus, updateStatusNoRef, updateUploadProgress } = fileUploadSlice.actions;
