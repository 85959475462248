import { sideMenuItems } from './constants';

export const getSidemenuItems = (is_subscribed: boolean) => {
  return sideMenuItems.map((item: any) =>
    item.mainPath === 'subscription'
      ? { ...item, urlPath: is_subscribed ? 'subscription/mysubscriptions' : 'subscription/create' }
      : item
  );
};

export const isCurrentMenuASidemenu = (menu: string): boolean => {
  const matches = sideMenuItems.some(
    (x: any) =>
      x.urlPath === menu.replace('/', '') ||
      (x.submenu &&
        x.submenu.some(
          (sub: any) =>
            sub.urlPath === menu.replace('/', '') || sub.chainUrls?.some((item: any) => item === menu.replace('/', ''))
        ))
  );
  return matches;
};

export const isCurrentMenuUseResourceFilter = (menu: string): boolean => {
  const matches = sideMenuItems.some((x: any) => x.urlPath === menu.replace('/', '') && x.hasResourceFilter);
  return matches;
};
