import { lazy, useEffect } from 'react';
import './App.scss';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import HasAuthenticatedWrapper from './components/RouteHelpers/HasAuthenticatedWrapper';
import ProtectedRouteWrapper from './components/RouteHelpers/ProtectedRouteWrapper';
import RouteWrapper from './components/RouteHelpers/RouteWrapper';
import Layout from './features/Layout/Layout';
import ViewPDFPageWrapper from './pages/ViewPDF/ViewPDFWrapper';

const HomePage = lazy(() => import('./pages/Home/HomePage'));
const UsersPage = lazy(() => import('./pages/Users/UsersPage'));
const MailerPage = lazy(() => import('./pages/Mailer/MailerPage'));
const TRRoutes = lazy(() => import('./pages/TR/TRRoutes'));
const SLRRoutes = lazy(() => import('./pages/SLR/SLRRoutes'));
const FTDRoutes = lazy(() => import('./pages/FTD/FTDRoutes'));
const PLRRoutes = lazy(() => import('./pages/PLR/PLRRoutes'));
const DVRRoutes = lazy(() => import('./pages/DVR/DVRRoutes'));
const NotAuthrizedPage = lazy(() => import('./pages/NotAuthorized/NotAuthorizedPage'));
const PDFviewer = lazy(() => import('./pages/PDFviewer/PDFviewer'));
const ViewPDFPage = lazy(() => import('./pages/ViewPDF/ViewPDFPage'));

function App() {
  //this will preload all the images that needs to be preloaded

  return (
    <div className="App" data-testid="app">
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route element={<HasAuthenticatedWrapper />}>
              <Route path="/" element={<HomePage />} />
            </Route>

            <Route element={<RouteWrapper />}>
              <Route path="/notauthorized" element={<NotAuthrizedPage />} />
            </Route>

            <Route element={<ProtectedRouteWrapper />}>
              <Route path="/users" element={<UsersPage />} />
              <Route path="/mailer" element={<MailerPage />} />
              <Route path="/tr/*" element={<TRRoutes />} />
              <Route path="/slr/*" element={<SLRRoutes />} />
              <Route path="/4td/*" element={<FTDRoutes />} />
              <Route path="/plr/*" element={<PLRRoutes />} />
              <Route path="/dvr/*" element={<DVRRoutes />} />
              <Route path="/pdf" element={<PDFviewer />} />
            </Route>

            <Route
              path="/view_pdf"
              element={
                <ViewPDFPageWrapper>
                  <ViewPDFPage />
                </ViewPDFPageWrapper>
              }
            />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </div>
  );
}

export default App;
