import { createSlice } from '@reduxjs/toolkit';

interface CheckedConceptZones {
  selectedConceptZone: any;
}

const initialState: CheckedConceptZones = {
  selectedConceptZone: [],
};

export const conceptZoneSlice = createSlice({
  name: 'checkedConceptZones',
  initialState,
  reducers: {
    resetChecked: (state) => {
      state.selectedConceptZone = initialState.selectedConceptZone;
    },
    setCheckedConceptZone: {
      reducer: (state, action: any) => {
        console.log({ action });
        state.selectedConceptZone = [...action.payload.selectedConceptZone];
      },
      prepare: (selectedConceptZone: any): any => {
        return {
          payload: {
            selectedConceptZone: [selectedConceptZone],
          },
        };
      },
    },
  },
});

export default conceptZoneSlice.reducer;

export const { setCheckedConceptZone, resetChecked } = conceptZoneSlice.actions;
