import React, { useState, useEffect } from 'react';
import './SideNavigation.scss';
import { NavLink, useLocation } from 'react-router-dom';
// import HexIcon from '../components/CustomIcons/HexIcon/HexIcon';
// import { useLogoutMutation } from '../store/services/authApi';
// import { ChevronHue, ChevronVibrant } from '../helpers/imagePreloader';
import useLayout from '../hooks/useLayout';
import { sideMenuItems } from '../Utils/constants';

// NOTE: I've COMMENTED A LOT OF FEATURES HERE
// BECAUSE THIS MENU IS FROM TR AND IT IS VERY DYNAMIC ALREADY
// I'M COMMENTING ALL THE UNNCESSARY FEATURES FOR NOW THAT MAY BE USED IN FUTURE

function SideNavigation() {
  const { isSideNavigationVisible, isSidemenuIconView } = useLayout();
  const location = useLocation();
  // const [logout, formStatus] = useLogoutMutation();
  const [collapsibleMenus, setCollapsibleMenus] = useState<any[]>([]);

  useEffect(() => {
    //check if the current URL is a collapsible menu or under a collapsible menu
    const pathname = location.pathname.replace('/', '');
    const collapsible = collapsibleMenus.find(
      (m: any) =>
        m.urlPath === pathname ||
        m.submenu?.find((x: any) => x.urlPath === pathname || x.chainUrls?.some((item: any) => item === pathname))
    );

    let updatedMenus: any[];
    if (collapsible) {
      updatedMenus = collapsibleMenus.map((menu) =>
        menu.urlPath === pathname ||
        menu.submenu?.find((x: any) => x.urlPath === pathname || x.chainUrls?.some((item: any) => item === pathname))
          ? {
              ...collapsible,
              isCollapsed: true,
            }
          : {
              ...menu,
              isCollapsed: false,
            }
      );
    } else {
      updatedMenus = collapsibleMenus.map((menu) => {
        return {
          ...menu,
          isCollapsed: false,
        };
      });
    }

    setCollapsibleMenus(updatedMenus);
  }, [location.pathname, sideMenuItems]);

  const getNestedMenu = (menu: any): JSX.Element | null => {
    const collapsible = collapsibleMenus.find((x: any) => x.displayName === menu.displayName);

    if (collapsible === undefined || collapsible === null || (collapsible && !collapsible.isCollapsed)) {
      //if not yet added, add it to the state
      if (collapsible === undefined || collapsible === null) {
        setCollapsibleMenus([...collapsibleMenus, menu]);
      }
      return null;
    }

    return (
      <li className="sub-menu flex flex-row">
        <div className="vertical-divider">
          <div />
        </div>
        <div className="w-100">
          <ul>
            {menu.submenu?.map((item: any, key: number) => (
              <li key={key}>
                <NavLink
                  to={item.urlPath}
                  className={({ isActive }) =>
                    isActive || item.chainUrls?.some((x: any) => x === location.pathname.replace('/', ''))
                      ? 'sidemenu-selected'
                      : ''
                  }
                  end
                >
                  <span>{item.displayName}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </li>
    );
  };

  // const getCarretIcon = (menuPath: string, currentPath: string) => {
  //   return menuPath && menuPath === currentPath ? (
  //     <i className="icon icon-chevron-up collapse-menu-icon" />
  //   ) : (
  //     <i className="icon icon-chevron-down collapse-menu-icon" />
  //   );
  // };

  const getMenu = (): JSX.Element[] => {
    const currentPath = location.pathname.split('/');

    return sideMenuItems.map((item, key) => {
      return (
        <React.Fragment key={key}>
          <li className="relative">
            <NavLink
              to={item.urlPath}
              className={({ isActive }) =>
                `${item.theme}-themed-box ${
                  isActive || (item.mainPath && item.mainPath === currentPath[1]) ? 'sidemenu-selected' : ''
                } ${item.submenu && item.submenu.length > 0 ? 'has-submenu' : ''}`
              }
              end
            >
              <>
                {/* <HexIcon icon={item.icon} colorTheme="green" isTextIcon={item.isTextIcon} colorMode="outlined" /> */}

                <span>{item.displayName}</span>

                {/* {item.submenu && item.submenu.length > 0 && getCarretIcon(item.mainPath, currentPath[1])} */}
              </>
            </NavLink>
          </li>

          {/* check if there's a nested menu and render it if there's any */}
          {item.submenu && !isSidemenuIconView && item.submenu.length > 0 && getNestedMenu(item)}
        </React.Fragment>
      );
    });
  };

  // useEffect(() => {
  //   if (formStatus.isError) {
  //     alert('Something went wrong. Please try again.');
  //   } else if (formStatus.isSuccess) {
  //     window.location.reload();
  //   }
  // }, [formStatus]);

  // const getChevIcon = () => {
  //   if (!isSidemenuIconView) {
  //     return <Image src={ChevronHue} className="chev-hue absolute" alt="Collapse menu" />;
  //   } else {
  //     return <Image src={ChevronVibrant} className="chev-vib absolute" alt="Collapse menu" />;
  //   }
  // };

  // const triggerLogout = () => {
  //   logout('');
  // };

  return (
    <>
      {isSideNavigationVisible ? (
        <aside
          className={`${isSideNavigationVisible ? 'sidemenu-visible ' : ''}${isSidemenuIconView ? 'sidemenu-icon-view' : ''}`}
        >
          {/* <section className="tr-subsite-row flex flex-row">
        <HexIcon icon="subsite-tr" colorTheme="green" colorMode="outlined" />
        <span>Teaching Resources</span>
      </section>

      <div className="relative w-100">
        <div className="row-divider" />
        <div className="toggle-icon-wrapper absolute clickable" onClick={() => toggleIconView()}>
          <div className="relative box-center">
            <i className="icon icon-hexagon" />
            {getChevIcon()}
          </div>
        </div>
      </div> */}

          <section className="menu-row">
            <ul className="flex flex-col">{getMenu()}</ul>
          </section>

          {/* 
      <div className="row-divider" />

      <section className="menu-row">
        <ul>
          <li onClick={triggerLogout}>
            <NavLink to="/subscription" end>
              <HexIcon icon="logout" colorTheme="green" colorMode="outlined" />
              <span>Logout</span>
            </NavLink>
          </li>
        </ul>
      </section> */}
        </aside>
      ) : (
        <></>
      )}
    </>
  );
}

export default SideNavigation;
