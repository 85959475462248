import { basePersistentApi } from '../baseApi';
import { gql } from 'graphql-request';

export const userContextApi = basePersistentApi.injectEndpoints({
  endpoints: (builder: any) => ({
    // queries
    getUserContext: builder.query({
      query: () => ({
        body: gql`
          query GetUserContext {
            getUserContext {
              id
              username
              email
              is_institution
              is_verified
              is_active
              user_profile {
                id
                first_name
                last_name
                date_of_birth
                is_real_birthdate
                primary_email
                secondary_email
                mobile_number
                affiliation
                state
                postcode
              }
              institution_profile {
                id
                institution_name
                primary_email
                address_line1
                address_line2
                city
                state
                postcode
                phone_number
                contact_person {
                  name
                  email
                  mobile_number
                }
              }
              roles {
                id
                role {
                  name
                  short_code
                  id
                  allowed_subsites {
                    short_code
                    name
                    url
                  }
                }
                assigned_by {
                  id
                  username
                  email
                  is_institution
                  user_profile {
                    id
                    first_name
                    last_name
                  }
                  institution_profile {
                    institution_name
                  }
                }
              }
              licenses {
                id
                is_assigned
                is_renewed
                date_assigned
                assigned_by
                assignee_email
                licence_assignee {
                  id
                  email
                  is_institution
                  user_profile {
                    first_name
                    last_name
                  }
                  institution_profile {
                    institution_name
                  }
                }
              }
            }
          }
        `,
      }),
      transformResponse: (res: any) => res.getUserContext,
    }),
  }),
  overrideExisting: false,
});

export const { useGetUserContextQuery, useLazyGetUserContextQuery } = userContextApi;
