import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../../../../store/store';

interface PageBuilderPageState {
  isShowBlocks: boolean;
  isShowTools: boolean;
  modalName: string;
  nodeId: string;
}

const initialState: PageBuilderPageState = {
  isShowBlocks: false,
  isShowTools: false,
  modalName: '',
  nodeId: '',
};

export const pageBuilderPageSlice = createSlice({
  name: 'pageBuilderPage',
  initialState,
  reducers: {
    toggleShowBlocks: (state) => {
      state.isShowBlocks = !state.isShowBlocks;
    },
    toggleShowTools: (state) => {
      state.isShowTools = !state.isShowTools;
    },
    setModalName: (state, action: PayloadAction<PageBuilderPageState['modalName']>) => {
      state.modalName = action.payload;
    },
    setNodeId: (state, action: PayloadAction<PageBuilderPageState['nodeId']>) => {
      state.nodeId = action.payload;
    },
    setModal: (
      state,
      action: PayloadAction<{ modalName: PageBuilderPageState['modalName']; nodeId: PageBuilderPageState['nodeId'] }>
    ) => {
      state.modalName = action.payload.modalName;
      state.nodeId = action.payload.nodeId;
    },
  },
});

export const { toggleShowBlocks, toggleShowTools, setModalName, setNodeId, setModal } = pageBuilderPageSlice.actions;

export const selectPageBuilderPage = (state: RootState) => state.pageBuilderPage;

export default pageBuilderPageSlice.reducer;
