import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../../../../store/store';

interface PDFUploaderPageState {
  pdfFile: File | undefined;
  pdfFileId: string;
  isDeleteModalOpen: boolean;
  isExistsModalOpen: boolean;
  isUpdateModalOpen: boolean;
  isOverwritePDFFile: boolean;
  existsUpload: {
    pdfFileId: string;
    file: File | undefined;
  };
}

const initialState: PDFUploaderPageState = {
  pdfFile: undefined,
  pdfFileId: '',
  isDeleteModalOpen: false,
  isExistsModalOpen: false,
  isUpdateModalOpen: false,
  isOverwritePDFFile: false,
  existsUpload: {
    pdfFileId: '',
    file: undefined,
  },
};

export const pdfUploaderPageSlice = createSlice({
  name: 'pdfUploaderPage',
  initialState,
  reducers: {
    reset: (state) => {
      state = initialState;
    },
    setPdfFile: (state, action: PayloadAction<PDFUploaderPageState['pdfFile']>) => {
      state.pdfFile = action.payload;
    },
    setPdfFileId: (state, action: PayloadAction<PDFUploaderPageState['pdfFileId']>) => {
      state.pdfFileId = action.payload;
    },
    openDeleteModal: (state) => {
      state.isDeleteModalOpen = true;
    },
    closeDeleteModal: (state) => {
      state.isDeleteModalOpen = false;
    },
    toggleDeleteModal: (state) => {
      state.isDeleteModalOpen = !state.isDeleteModalOpen;
    },
    openExistsModal: (state) => {
      state.isExistsModalOpen = true;
    },
    closeExistsModal: (state) => {
      state.isExistsModalOpen = false;
    },
    toggleExistsModal: (state) => {
      state.isExistsModalOpen = !state.isExistsModalOpen;
    },
    openUpdateModal: (state) => {
      state.isUpdateModalOpen = true;
    },
    closeUpdateModal: (state) => {
      state.isUpdateModalOpen = false;
    },
    toggleUpdateModal: (state) => {
      state.isUpdateModalOpen = !state.isExistsModalOpen;
    },
    setIsOverwritePDFFile: (state, action: PayloadAction<PDFUploaderPageState['isOverwritePDFFile']>) => {
      state.isOverwritePDFFile = action.payload;
    },
    setExistsUpload: (state, action: PayloadAction<PDFUploaderPageState['existsUpload']>) => {
      state.existsUpload = action.payload;
    },
    resetExistsUpload: (state) => {
      state.existsUpload = initialState.existsUpload;
    },
  },
});

export const {
  reset,
  setPdfFile,
  setPdfFileId,
  openDeleteModal,
  closeDeleteModal,
  toggleDeleteModal,
  openExistsModal,
  closeExistsModal,
  toggleExistsModal,
  openUpdateModal,
  closeUpdateModal,
  toggleUpdateModal,
  setIsOverwritePDFFile,
  setExistsUpload,
  resetExistsUpload,
} = pdfUploaderPageSlice.actions;

export const selectPDFUploader = (state: RootState) => state.pdfUploaderPage;

export default pdfUploaderPageSlice.reducer;
