import React, { Suspense, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import LoadingScreen from '../../components/Loading/LoadingScreen/LoadingScreen';

const RouteWrapper = () => {
  const location = useLocation();

  //ensures that the screen scrolls to top when location changes
  useEffect(() => window.scrollTo(0, 0), [location]);

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Outlet />
    </Suspense>
  );
};

export default RouteWrapper;
