import { createSlice } from '@reduxjs/toolkit';

interface PreviewElementGroup {
  selectedPreviewElements: any;
}

const initialState: PreviewElementGroup = {
  selectedPreviewElements: [],
};

export const elementGroupSlice = createSlice({
  name: 'previewElements',
  initialState,
  reducers: {
    resetPreview: (state) => {
      state.selectedPreviewElements = initialState.selectedPreviewElements;
    },
    setSelectedElGroup: {
      reducer: (state, action: any) => {
        state.selectedPreviewElements = action.payload.selectedPreviewElements;
      },
      prepare: (selectedPreviewElements: string): any => {
        return {
          payload: {
            selectedPreviewElements,
          },
        };
      },
    },
  },
});

export default elementGroupSlice.reducer;

export const { setSelectedElGroup, resetPreview } = elementGroupSlice.actions;
